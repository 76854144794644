
declare const DOMAIN : string
export const domain = DOMAIN;

declare const APP_BUILD : boolean

let service = '' 
if ( APP_BUILD ) {
	service = domain
}
/** serviceUrl wird nur für App Requests vom Server verwendet (API, Bilder) */
export const serviceUrl = service;
