import { User, IUser } from "./user";
import { prop, DDarray, DDevent, watch } from "ddt/DDmodel";
import { Teams, ITeam, Team } from "../team/teams";
import { ModuleSettings, Setting } from "../../controllers/storage";


export interface ISiteuser {
	loginStatus	: number
	user		: IUser | User | null
	teams		: ITeam[] | Teams
	rights		: string[] | DDarray<string>
}

export class Siteuser extends ModuleSettings implements ISiteuser {

	// Login Status
	/** ERROR_FORBIDDEN = 0 */
	static ERROR_FORBIDDEN			=  0;
	/** ERROR_NOT_LOGGED_IN = 1 */
	static ERROR_NOT_LOGGED_IN		=  1;
	/** LOGIN_OK = 2 */
	static LOGIN_OK					=  2;
	/** ERROR_EMAIL_INVALID = 4 */
	static ERROR_EMAIL_INVALID		=  4;
	/** ERROR_DSE_NOT_ACCEPTED = 8 */
	static ERROR_DSE_NOT_ACCEPTED	=  8;
	
	@prop() loginStatus	: number
	
	@prop( () => User )
	user				: User | null
	
	@prop( () => Teams )
	teams				: Teams
	
	@prop( () => DDarray )
	rights				: DDarray<string>
	
	@prop( () => Team )
	team				: Team | null

	@Setting() 
	teamid				: string

	@Setting() 
	teamids				: string[]
	
	@Setting() 
	loginRedirect		: string
	
	@Setting()
	authToken			: string
	
	@Setting()
	mapsOptIn			: boolean
	
	constructor() {
		super( 'siteuser', {} );
	
		this.teams			= new Teams();
		this.reset()
	}

	/**
	 * reset site user
	 **/
	reset() {

		this.user = null;
		this.teams.length = 0
		if ( this.rights ) {
			this.rights.length = 0
		}
		this.team			= null;
		
		this.loginStatus	= Siteuser.ERROR_NOT_LOGGED_IN;
		this.loginRedirect	= '';
		this.authToken		= '';
		this.teamid			= ''
		this.teamids		= [];
		this.mapsOptIn		= false;

	}
	
	/**
	 * userid des Siteusers, oder ''
	 */
	userid() : string {
		return this.user ? this.user.id : '';
	}
	
	hasRightOf( right : string ) : boolean {
		return !!this.rights && ( this.rights.indexOf( right ) >= 0 );
	}
	
	@watch( 'team' )
	watchTeam( e : DDevent ) {
		if ( this.team ) {
			this.teamid = this.team.id;

			if ( this.teams.length > 1 ) {
				let index = this.teams.indexOf(this.team)
				// Team nach vorne sortieren
				if ( index > 0 ) {
					this.teams.splice( index, 1 )
					this.teams.unshift( this.team )
				}
	
				// Reihenfolge der Teams neu speichern
				let teamids : string[] = []
				this.teams.forEach( (team) => {
					teamids.push(team.id)
				})
				this.teamids = teamids
			}
		} else {
			this.teamid = '';
		}
	}
	
	@watch( 'loginStatus' )
	loginOk() : boolean {
		return this.loginStatus == Siteuser.LOGIN_OK;
	}

	@watch( 'teams.length' )
	watchTeamsLength( e : DDevent ) {
		if ( !e.prevValue ) {
			// wenn .teams initialisiert wird
			//console.log( 'Siteuser.watchTeams()', e.type, e.value, e.prevValue )
			if ( this.teamids.length ) {
				//console.log( 'Siteuser.watchTeams()', 'sort' )
				// es existiert schon eine gespeicherte Sortierung
				// teams nach teamids sortieren
				this.teams.sort( (a, b) => {
					return this.teamids.indexOf(a.id) - this.teamids.indexOf(b.id)
				} )
			}
		}
	}
	
}

var _siteuser : Siteuser | null = null

export const siteuser = function() : Siteuser {
	if ( !_siteuser ) {
		_siteuser = new Siteuser();
	}
	return _siteuser
}
