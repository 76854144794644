/**
 * Web-App Loader
 * 
 * lädt auch polyfills und globale cKomponenten und scss
 */

import "../components/loading-spinner/loading-spinner";
import "../styles/shared/scss/clickable.scss";
import "../styles/shared/scss/button.scss";
import "../styles/layouts/scss/background.scss";
//import "intersection-observer";
//import "web-animations-js/web-animations.min";
import "../site/sticky-trigger";
import { appRouter } from "./router";
import { userProvider } from "../api/user/user-provider";
import { AppStorage } from "../controllers/storage";
import { siteuser } from "../api/user/siteuser";
import { pushProvider } from "../controllers/push-provider";
import { hydrateHomeLinks } from "../site/rbl-logo/hydrate-home-link";
import { setPhpDateLocale, phpDateLocaleDe } from "../utils/php-date";
import { log } from "./app-log";
import { Logger } from "./app-log";
import { PageDispatchError } from "./page-dispatcher";
import { startServiceWorker } from "./sw-register";

if( !('animate' in document.body) ) {
	// load in polyfill for the Web Animation API
	console.log( 'loading Web Animation API polyfill')
	import( /* webpackChunkName: "web-animations-polyfill" */  "../utils/web-animations-polyfill" );
}
if ( !('IntersectionObserver' in window) ) {
	// load in polyfill for the  Intersection Observer API
	console.log( 'loading ntersection Observer API polyfill')
	import( /* webpackChunkName: "intersection-observer-polyfill" */  "intersection-observer" );
}

/**
 * force Webpack's ProvidePlugin to import dom4, fetch, Promise polyfills (if defined) 
 **/
declare let dom4 : any
if ( typeof dom4		== 'undefined' ) { /* make variable used */ }
if ( typeof fetch		== 'undefined' ) { /* make variable used */ }
if ( typeof Promise		== 'undefined' ) { /* make variable used */ }
// force Webpack's ProvidePlugin to import es2015 polyfills (eg. url from corejs)
//declare var es2015Polyfills
//if ( typeof es2015Polyfills	== 'undefined' ) { /* make variable used */ }
//// force Webpack's ProvidePlugin to import service-worker or sw-unregister
//declare let ImportSw : any
//if ( typeof ImportSw	== 'undefined' ) { /* make variable used */ }
// force Webpack's ProvidePlugin to import manually defined APP assets
declare let ImportAssets : any
if ( typeof ImportAssets	== 'undefined' ) { /* make variable used */ }
// force Webpack's ProvidePlugin to import splash screen (APP only)
declare let ImportSplash : any
if ( typeof ImportSplash	== 'undefined' ) { /* make variable used */ }
// force Webpack's ProvidePlugin to import back button behaviour (APP only)
declare let ImportBackButton : any
if ( typeof ImportBackButton	== 'undefined' ) { /* make variable used */ }

// diagnose:
declare const APP_BUILD : boolean
var logPotoschnik = false

startServiceWorker();

AppStorage.init()
.then( (res) => {
	// console.log( 'initAppStorage:', res );
	// initialise push (app only)
	return pushProvider.init()
})
.then( (registred) => {
//	console.log( 'initPush:', registred );
	// load Siteuser settings
	return siteuser().load()
})
.then( () => {
	// load siteuser
//	log( 'Siteuser Settings', {
//		hasAuthToken: !!siteuser.authToken,
//	} )
	return userProvider().loadSiteuser()
})
.then( (data) => {
	// update push registration (app only)
	let userid = siteuser().userid();
	if ( userid == 'o59605' && APP_BUILD ) {
		logPotoschnik = true
		log( 'Siteuser load', {
			fromCache	: data.fromCache,
			userid		: userid,
			loginStatus	: siteuser().loginStatus,
		} )
	}
	return pushProvider.updateSubscriptionState( userid )
})
.then( () => {

	// apply language
	setPhpDateLocale( phpDateLocaleDe );
	
	appRouter.startPage = '/index.php';
	
	if ( pushProvider.action && pushProvider.action.notification.data.url ) {
		// comming from a tap on push notification (app only)
		return appRouter.loadPage( pushProvider.action.notification.data.url )
	} else {
		if ( logPotoschnik ) {
			log( 'loadPage', {
				href	: location.href,
			} )
		}
		return appRouter.loadPage( location.href )
	}
})
.then( () => {
	// check if the window has already been refreshed due to a ChunkLoadError
	return AppStorage.getItem( "rbl-cache", 'retry-after-cle' )
})
.then( ( val ) => {
	// success so reset the refresh
	if ( val && val.hasRefreshed ) {
		return AppStorage.setItem( "rbl-cache", 'retry-after-cle', { hasRefreshed: false } );
	}
	return 
})
.catch( (reason) => {
	if ( !(reason instanceof PageDispatchError) ) {
		if ( (reason instanceof Error) && (reason.name == 'ChunkLoadError') ) {
			// Web-Seite neu laden ...
			if ( logPotoschnik ) {
				log( reason, undefined, Logger.ERROR )
			}
			AppStorage.setItem( "rbl-cache", 'retry-after-cle', { hasRefreshed: true } )
			.then( () => {
				// ... falls 'retry-after-cle' gesetzt werden konnte
				window.setTimeout( 
					() => {
						window.location.reload();
					},
					3000
				)
			})
		} else {
			console.error( reason );
			log( reason, undefined, Logger.ERROR )
		}
	} else {
		console.log( reason );
		appRouter.alertFailReason( 'Die Seite "' + reason.pageKey + '" existiert leider nicht!', false, false)
	}
})
.then( () => {
	// RBL - Logo Link zu index.php durch Router leiten
	hydrateHomeLinks()
})
